import { all, call, put, takeLatest, fork, select } from 'redux-saga/effects'
import { dismissSnackBar, dismissErrorSnackBar } from 'utils/generators'
import { ACTIONS } from 'store/users'
import { ROUTE_URL } from 'constants.js'
import {
  performFetchUsers,
  performFetchInvites,
  performNewInvite,
  performNewCompanyInvite,
  performNewBulkCompanyInvite,
  performValidateBulkCompanyInvite,
  performResetMFAUser,
  performDeactivateUser,
  performReactivateUser,
} from '../requests'
import { push } from 'connected-react-router'

const transformInvites = (invites) =>
  invites?.map((invite) => ({
    ...invite,
    ...invite.inviteDetails,
  }))

const getAuthState = (state) => state.auth

export function* fetchUsers() {
  const { companyId } = yield select(getAuthState)
  const { FETCH_USERS_SUCCESS } = ACTIONS
  const [users, invites] = yield all([call(performFetchUsers, { companyId }), call(performFetchInvites)])

  yield put({
    type: FETCH_USERS_SUCCESS,
    payload: {
      users: users.data,
      invites: transformInvites(invites.data),
    },
  })
}

export function* inviteUser({ payload }) {
  const { name, email, role, companyId } = payload
  const { INVITE_USER_SUCCESS, INVITE_USER_FAILURE, CLOSE_SNACKBAR } = ACTIONS
  try {
    const invite = {
      to: email,
      inviteType: 'USER',
      inviteDetails: {
        name,
        role,
        companyId
      },
    }
    yield call(performNewInvite, invite)
    yield put({
      type: INVITE_USER_SUCCESS,
    })
    yield fork(dismissSnackBar, CLOSE_SNACKBAR)
    yield put(push(ROUTE_URL.entityProfile))
  } catch (e) {
    yield put({
      type: INVITE_USER_FAILURE,
      payload: e.message,
    })
    yield fork(dismissErrorSnackBar, CLOSE_SNACKBAR)
  }
}

export function* resetMFAUser({ payload }) {
  const email = payload
  const { RESET_MFA_SUCCESS, RESET_MFA_FAILURE, CLOSE_SNACKBAR } = ACTIONS
  try {
    yield call(performResetMFAUser, email)
    yield put({
      type: RESET_MFA_SUCCESS,
    })
    yield call(fetchUsers)
    yield fork(dismissSnackBar, CLOSE_SNACKBAR)
  } catch (e) {
    yield put({
      type: RESET_MFA_FAILURE,
      payload: e.message,
    })
    yield fork(dismissErrorSnackBar, CLOSE_SNACKBAR)
  }
}

export function* toggleUser({ payload }) {
  const { id, deactivate } = payload
  const { TOGGLE_USER_SUCCESS, TOGGLE_USER_FAILURE, CLOSE_SNACKBAR } = ACTIONS
  try {
    if (deactivate) {
      yield call(performDeactivateUser, id)
    } else {
      yield call(performReactivateUser, id)
    }
    yield put({
      type: TOGGLE_USER_SUCCESS,
      payload: deactivate,
    })
    yield call(fetchUsers)
    yield fork(dismissSnackBar, CLOSE_SNACKBAR)
  } catch (e) {
    yield put({
      type: TOGGLE_USER_FAILURE,
      payload: e.message,
    })
    yield fork(dismissErrorSnackBar, CLOSE_SNACKBAR)
  }
}

export function* validateInviteCompany({ payload }) {
  const { VALIDATE_INVITE_COMPANY_SUCCESS, VALIDATE_INVITE_COMPANY_FAILURE, CLOSE_SNACKBAR, SET_FETCHING } = ACTIONS
  try {
    yield put({
      type: SET_FETCHING,
      payload: true,
    })
    const file = payload
    const result = yield call(performValidateBulkCompanyInvite, file)
    yield put({
      type: VALIDATE_INVITE_COMPANY_SUCCESS,
      payload: result,
    })
    yield fork(dismissSnackBar, CLOSE_SNACKBAR)
  } catch (e) {
    yield put({
      type: VALIDATE_INVITE_COMPANY_FAILURE,
      payload: e.message,
    })
    yield fork(dismissErrorSnackBar, CLOSE_SNACKBAR)
  } finally {
    yield put({
      type: SET_FETCHING,
      payload: false,
    })
  }
}

export function* inviteCompany({ payload }) {
  const { INVITE_COMPANY_SUCCESS, INVITE_COMPANY_FAILURE, CLOSE_SNACKBAR, SET_FETCHING } = ACTIONS
  try {
    yield put({
      type: SET_FETCHING,
      payload: true,
    })
    let result
    if (payload?.file) {
      const { file, ...data } = payload
      result = yield call(performNewBulkCompanyInvite, file, data)
    } else {
      const { email, companyName, name, ...extra } = payload
      const invite = {
        to: email,
        inviteType: 'COMPANY',
        inviteDetails: {
          companyName,
          name,
        },
        ...extra,
      }
      yield call(performNewCompanyInvite, invite)
    }
    yield put({
      type: INVITE_COMPANY_SUCCESS,
      payload: result,
    })
    yield fork(dismissSnackBar, CLOSE_SNACKBAR)
  } catch (e) {
    yield put({
      type: INVITE_COMPANY_FAILURE,
      payload: e.message,
    })
    yield fork(dismissErrorSnackBar, CLOSE_SNACKBAR)
  } finally {
    yield put({
      type: SET_FETCHING,
      payload: false,
    })
  }
}

export default function* rootSaga() {
  const { FETCH_USERS, INVITE_USER, INVITE_COMPANY, RESET_MFA, TOGGLE_USER, VALIDATE_INVITE_COMPANY } = ACTIONS
  yield all([
    takeLatest(FETCH_USERS, fetchUsers),
    takeLatest(RESET_MFA, resetMFAUser),
    takeLatest(INVITE_USER, inviteUser),
    takeLatest(TOGGLE_USER, toggleUser),
    takeLatest(INVITE_COMPANY, inviteCompany),
    takeLatest(VALIDATE_INVITE_COMPANY, validateInviteCompany),
  ])
}
